import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import PageTransition from 'gatsby-plugin-page-transitions';

import { responsiveTitle1 } from '../components/typography.module.css'

export const query = graphql`
query VinyasaSlowPageQuery {
  projects: allSanityProject(limit: 12, sort: {fields: [publishedAt], order: DESC}, filter: {categories: {elemMatch: {title: {eq: "Vinyasa Slow Flow"}}}}) {
    edges {
      node {
        id
        mainImage {
          asset {
            _id
          }
          alt
        }
        title
        _rawExcerpt
        slug {
          current
        }
      }
    }
  }
}
`

const VinyasaSlowClassPage = props => {
  const { data, errors } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const projectNodes =
    data && data.projects && mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs)
  return (
    <Layout>
      <PageTransition>
      <SEO title='Classes' />
      <Container>
        <h1 data-sal="slide-up" data-sal-delay="10" data-sal-duration="600" data-sal-easing="ease" className={responsiveTitle1}>Vinyasa Slow Flow</h1>
        {projectNodes && projectNodes.length > 0 && <ProjectPreviewGrid nodes={projectNodes} />}
      </Container>
      </PageTransition>
    </Layout>
  )
}

export default VinyasaSlowClassPage
